import request from '@/utils/request';

// 问卷详情
export const questionnaireDetail = id => request.get(`study/questionnaire/${id}/detail`)

// 提交问卷
export const questionnaireSubmit = (ids, p) => request.post(`study/activity/questionnaire/${ids.taskId}/${ids.detailId}/${ids.questionnaireId}/${ids.evalType}/${ids.userId || 0}/submit`, p)

// 课程-提交问卷
export const courseQuestionnaireSubmit = (p) => request.post(`study/course/questionnaire/submit`, p)

// 查看问卷
export const questionnaireResult = (ids, p) => request.get(`study/train/questionnaire/${ids.taskId}/${ids.detailId}/${ids.questionnaireId}/${ids.evalType}/${ids.taskaskId || 0}/detail`, p)

// 课程-查看问卷
export const courseQuestionnaireResult = (p) => request.post(`study/course/questionnaire/detail`, p)

// 提交问卷
// export const questionnaireSubmit = (ids, p) => request.post(`study/train/questionnaire/${ids.taskId}/${ids.detailId}/${ids.questionnaireId}/${ids.evalType}/submit`, p)